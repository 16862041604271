@mixin gradient-text($gradient, $bg : 'light') {
  @supports(mix-blend-mode: lighten) {
    display: inline-block;
    position: relative;
    
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
      
      background: unquote($gradient);
      pointer-events: none;
    }

    @if ($bg == 'light') {
      color: #000;
      background: #fff;
      mix-blend-mode: multiply;

      &::before {
        mix-blend-mode: screen;
      }
    } @else {
      color: #fff;
      background: #000;
      mix-blend-mode: lighten;

      &::before {
        mix-blend-mode: multiply;
      }
    }
  }
}


$primary:     #fff;
$secondary:   #000;

html{
  background: url(../../images/bg.jpg) no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

body{
  color:#fff;
  background-color:transparent;
  //font-family: 'Love Ya Like A Sister', cursive;
  font-family: 'Patrick Hand', cursive;
  font-size:1.4rem;
}

.container.main{
  background-color:$secondary;
  background: url(../../images/board.jpg) no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.header{
  color:#fff;
  margin-top:5rem;
  min-height:auto;
  @media (min-width: 48em) {
    background-position: right;
    min-height:200px;
  }
  @media (min-width: 62em) {
    background-position: right;
    min-height:300px;
  }
  @media (min-width: 75em) {
    background-position: right;
    min-height:300px;
  }
}

.main-title{

    //font-family: 'Passion One', cursive;

    //font-family: 'Michroma', sans-serif;
}

a{
  text-decoration:none;
  &:link,&:active,&:visited{
    text-decoration:none;
    color:#fff;
  }
  &:focus,&:hover{
    text-decoration:none;
    color:$primary;
  }
  &.colour{
    &:link,&:active,&:visited{
      text-decoration:none;
      color:#fff;
      //@include gradient-text('linear-gradient(to right, #cf2424, #1156be)', 'light');
    }
    &:focus,&:hover{
      text-decoration:none;
      color:$primary;
    }
  }
}

#wrapper {
  padding-left: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#wrapper.toggled {
  padding-left: 250px;
}

#side-wrapper {
  z-index: 99999;
  position: fixed;
  left: 250px;
  width: 0;
  height: 100%;
  margin-left: -250px;
  overflow-y: auto;
  background: #fff;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#wrapper.toggled #side-wrapper {
  width: 250px;
}

#page-content-wrapper {
  width: 100%;
  position: absolute;
  padding: 0;
}

#wrapper.toggled #page-content-wrapper {
  position: absolute;
  margin-right: -250px;
}

#menu-toggle{
  position: relative;
  @include gradient-text('linear-gradient(to right, #cf2424, #cf0059)', 'light');
  &.toggled{
  }
  a{
    text-decoration:none;
    &:link,&:visited,&:active,&:hover{
      color:$dark;
    }
  }
}


/* Sidebar Styles */

.side-nav {
  position: absolute;
  top: 0;
  width: 250px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.side-nav li {
  text-indent: 20px;
  line-height: 40px;
}

.side-nav li a {
  display: block;
  text-decoration: none;
  color: $dark;
}

.side-nav li a:hover {
  text-decoration: none;
  color: $primary;
  background: transparent;
}

.side-nav li a:active, .side-nav li a:focus {
  text-decoration: none;
}

.side-nav>.side-brand {
  height: 65px;
  font-size: 18px;
  line-height: 60px;
  font-family: 'munki-boy';
  font-size: 1.2rem;
  font-weight: 500;
}

.side-nav>.side-brand a {
  color: $dark;
}

.side-nav>.side-brand a:hover {
  color: $primary;
  background: none;
}

@media(min-width:768px) {
  #wrapper {
    padding-left: 0;
  }
  #wrapper.toggled {
    padding-left: 250px;
  }
  #side-wrapper {
    width: 0;
  }
  #wrapper.toggled #side-wrapper {
    width: 250px;
  }
  #page-content-wrapper {
    padding: 0;
    position: relative;
  }
  #wrapper.toggled #page-content-wrapper {
    position: relative;
    margin-right: 0;
  }
}











div.left-hander{
  .box1{
    width:50%;
    margin-left:50%;
    background-color:#fff;
     background-image: linear-gradient(to right, #b61988, #8340ad, #1156be);
    position: relative;
    border-bottom-right-radius: 2em;
    padding-right:4px;
    padding-bottom:4px;
    .box1inner{
      background-color:#fff;
      border-bottom-right-radius: 1.7em;
      padding:20px;
    }
  }
  .box2{
    background-color: #00f;
    background-image: linear-gradient(to right, #cf2424, #cf0059, #b61988);
    padding-left: 40px;
    padding-top: 4px;
    margin-top:-4px;
    position: relative;
    width: 50%;
    border-top-left-radius: 2em;
    .box2inner{
      background-color:#fff;
      border-top-left-radius: 1.7em;
      padding:20px;
    }
  }
}

div.right-hander{
  .box1{
    width:50%;
    background-color:#fff;
    background-image: linear-gradient(to right, #cf2424, #cf0059, #b61988);
    position: relative;
    border-bottom-left-radius: 2em;
    padding-left:40px;
    padding-bottom:4px;
    .box1inner{
      background-color:#fff;
      border-bottom-left-radius: 1.7em;
      padding:20px;
    }
  }
  .box2{
    background-color: #00f;
    background-image: linear-gradient(to right, #b61988, #8340ad, #1156be);
    padding-right: 4px;
    padding-top: 4px;
    margin-top:-4px;
    position: relative;
    width: 50%;
    margin-left:50%;
    border-top-right-radius: 2em;
    .box2inner{
      background-color:#fff;
      border-top-right-radius: 1.7em;
      padding:20px;
    }
  }
}

article, header{
  &.left-hander{
    background-image: linear-gradient(to right, #cf2424, #cf0059, #b61988);
    padding-left:40px;
    .article-inner{
      background-color:#fff;
      padding-left: 2vw;
      padding-right: 2vw;
      padding-top:1rem;
      padding-bottom:1rem;
    }
  }
  &.right-hander{
    background-image: linear-gradient(to right, #b61988, #8340ad, #1156be);
    padding-right:4px;
    .article-inner{
      background-color:#fff;
      padding-left: 2vw;
      padding-right: 2vw;
      padding-top:1rem;
      padding-bottom:1rem;
    }
  }
}


iframe{

  &.map, &.youtube{
    border:none;
    height: 500px;
    width: 100%;
    margin-bottom: 30px;

  }

}

a.youtube-thumb,
a.youtube-link {
  text-decoration: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
}

.video-container { 
  position: relative; 
  padding-bottom: 56.25%; 
  padding-top: 30px; 
  height: 0; 
  overflow: hidden; 

  &.home{
    margin-top: 30px;
    margin-bottom: 30px;
  }

} 
.video-container iframe, .video-container object, .video-container embed { 
  position: absolute; 
  top: 0; 
  left: 0; 
  width: 100%; 
  height: 100%;
}

.card-columns{
  column-count: 1;
  @media (min-width: 34em) {
    column-count: 1;
  } 
  @media (min-width: 48em) {
    column-count: 2;
  }
  @media (min-width: 62em) {
    column-count: 4;
  }
  @media (min-width: 75em) {
    column-count: 4;
  }
}

.table th, .table td{
  border-top:none;
}

.btn-primary{
   background-color:#000;
   //background-image: linear-gradient(to right, #cf2424, #cf0059, #b61988);
   border:none;
   color:#fff;
   &:hover,&:active,&focus{
    background-color:darken(#000, 15);
    color:#fff;
   }
   &:link,&:visited{
    box-shadow: 2px #000;
    color:#fff;
   }
}


.btn-primary:hover {
  color: #fff;
  background-color: darken(#000, 15);
  border:none;
}

.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem darken(#000, 15);
  color:#fff;
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #000;
  border:none;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: darken(#000, 15);
  border:none;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem #000;
  color:#fff;
}

